import React, { useEffect } from 'react';
import './home.css';
import Carrousel from '../../components/Carrousel';
import Button from '@mui/material/Button';
import { Fab } from '@mui/material';
import home1 from '../../assets/images/generals/home1.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import tech1 from '../../assets/images/technologies/tech1.png';
import tech2 from '../../assets/images/technologies/tech2.png';
import tech3 from '../../assets/images/technologies/tech3.png';
import tech4 from '../../assets/images/technologies/tech4.png';
import tech5 from '../../assets/images/technologies/tech5.png';
import tech6 from '../../assets/images/technologies/tech6.png';
import tech7 from '../../assets/images/technologies/tech7.png';
import tech8 from '../../assets/images/technologies/tech8.png';
import tech9 from '../../assets/images/technologies/tech9.png';
import tech10 from '../../assets/images/technologies/tech10.png';
import Card from '../../components/Card';
import Contact from '../../components/Contact/contact';
import About from '../../components/About/about';
import Footer from '../../components/Footer/footer';


function Home() {

    const [services, setServices] = React.useState([]);

    useEffect(() => {
        fetch('data/services.json')
            .then((r) => r.json())
            .then((data) => {
                setServices(data);
                console.log(data)
            })
    }, []);


    return (
        <>
            <Fab id='homeFab' variant="extended" href={process.env.REACT_APP_WHATSAPP} target="_blank">   
                <WhatsAppIcon sx={{ mr: 1 }} />
                Contáctanos
            </Fab>
            <div id='home' className='homePrincipal'>
                <div className='homePrincipalTitle'>
                    <h1>Soluciones innovadoras, de la mano de Terra</h1>
                    <p>Confía en Terra y descubre cómo podemos transformar tu negocio en una fuente de excelencia y rentabilidad en el mercado actualmente competitivo.
                        Desarrollamos soluciones digitales para tus necesidades específicas.</p>
                    <Button variant="contained" className='ButtonHome' href='#contactenos' >Cotiza aquí</Button>
                </div>
                <img className='homePrincipalImage' src={home1} alt="SRC" />
            </div>
            
            <div id='proyectos' className='homeCarrousel'>
                <h2>Proyectos realizados por Terra</h2>
                <Carrousel />
            </div>
            <div className='homeTech'>
                <h2>Tecnologías</h2>
                <div className='homeTechnologies'>
                    <img src={tech1} className='homeTechnology' />
                    <img src={tech2} className='homeTechnology' />
                    <img src={tech3} className='homeTechnology' />
                    <img src={tech4} className='homeTechnology' />
                    <img src={tech5} className='homeTechnology' />
                    <img src={tech6} className='homeTechnology' />
                    <img src={tech7} className='homeTechnology' />
                    <img src={tech8} className='homeTechnology' />
                    <img src={tech9} className='homeTechnology' />
                    <img src={tech10} className='homeTechnology' />
                </div>
            </div>
            <div id='servicios' className='homeServices'>
                <h2>Nuestros Servicios</h2>
                <div className='homeServicesCards'>
                    {services.map(service => {
                    return <Card title={service.title} description={service.description} imageurl={require(`../../assets/images/services/${service.urlImage}`)} position={service.position}/>
                    })}

                </div>
            </div>
            <div id='somos' className='homeAbout'>
                <About/>
            </div>
            <div id='contactenos' className='homeContact'>
                <Contact/>
            </div>
            <div className='homeFooter'>
                <Footer/>
            </div>
        </>
    );
}

export default Home;