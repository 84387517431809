import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './cardCarrousel.css'



function CardCarrousel({
  title,
  description,
  urlImage
}) {

  return (
    <Card className='CardCarrousel' sx={{ maxWidth: '65%' }}>
      <CardMedia
        className='cardMedia'
        sx={{ height: '20vh' }}
        image={require(`../../assets/images/projects/${urlImage}`)}
      />
      <CardContent>
        <h3>{title}</h3>
        <p>{description}</p>
      </CardContent>
      {/* <Button variant="contained" className='carButton'>Conoce más</Button> */}
    </Card>
  );
}
export default CardCarrousel;