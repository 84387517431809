import React, { useEffect } from 'react';
import './footer.css';
import Logo from '../../assets/images/logos/logoTerraWhite.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';

export default function Footer() {

    return (
      <>
      <div  class='containerFooter'>
        <img src={Logo}/>
        <h3>Empresa 100% colombiana 🇨🇴</h3>
        <IconButton href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank">
          <InstagramIcon id='icon'/>
        </IconButton>
        <IconButton href={process.env.REACT_APP_FACEBOOK_URL} target="_blank">
          <FacebookIcon id='icon'/>
        </IconButton>
        <IconButton href={process.env.REACT_APP_WHATSAPP} target="_blank">
          <WhatsAppIcon id='icon'/>
        </IconButton>
        <h5 id='nameTerra'>Terra Development SAS</h5>
        <br/>
      </div>
      </>
    );
  };