import { useEffect, useState } from "react";
import * as React from 'react';
import emailjs from '@emailjs/browser';
import { TextField, Button, Box, Container, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";
import CircularProgress from '@mui/material/CircularProgress';
import home1 from '../../assets/images/generals/contact.png';
import './contact.css';

export default function Contact() {

    const [nameText, nameSetText] = useState("");
    const [emailText, emailSetText] = useState("");
    const [celText, celSetText] = useState("");
    const [messageText, messageSetText] = useState("");

    const [modalTitle, modalSetTitle] = useState("");
    const [modalText, modalSetText] = useState("");

    const [openLoader, setOpenLoader] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const handleCloseModal = () => setOpenModal(false);

    const [token, setToken] = useState('')
    const verifyRecaptchaCallback = React.useCallback((token) => {
        setToken(token)
    }, []);

    useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);
        const handleSubmit = async (e) => {
        setOpenLoader(true);
        e.preventDefault();
        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID
        try {
            await emailjs.send(serviceId, templateId, {
            name: nameText,
            email: emailText,
            cellphone: celText,
            message: messageText
            });
            nameSetText("")
            emailSetText("")
            celSetText("")
            messageSetText("")
            setOpenLoader(false);
            modalSetTitle("Datos enviados correctamente")
            modalSetText("Lo más pronto posible nos pondremos en contacto contigo.")
            setOpenModal(true)
        } catch (error) {
            setOpenLoader(false);
            modalSetTitle("Hubo un error en el envió")
            modalSetText("Lo sentimos, intentalo nuevamente.")
            setOpenModal(true)
        } finally {
            setOpenLoader(false);
        }
    };

    return (
      <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div class='contactContainer'>
        <div class='contactImage'>
            <h2>Contáctanos</h2>
            <img src={home1} alt="SRC"/>
        </div>
        <div id='contactForm'>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <Container id='container' component="main" maxWidth="l">
        <Box
            sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
            onSubmit={handleSubmit}
        >
            <Box component="form" sx={{ mt: 0 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nombre"
                name="nombre"
                value={nameText}
                onChange = {(e) => {
                    nameSetText(e.target.value);
                 }}
                variant='standard'
                InputProps={ { disableUnderline: true, sx: { '& input': {paddingLeft: '5px'}} }}
                InputLabelProps={{ shrink: true, style: { marginTop: '-5px', color: 'white'} }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="correo"
                type="email"
                value={emailText}
                onChange = {(e) => {
                    emailSetText(e.target.value);
                 }}
                variant='standard'
                InputProps={ { disableUnderline: true, sx: { '& input': {paddingLeft: '5px'}} }}
                InputLabelProps={{ shrink: true, style: { marginTop: '-5px', color: 'white'} }}
            />
            <TextField
                margin="normal"
                fullWidth
                id="cellphone"
                label="Celular"
                name="celular"
                type="phone"
                value={celText}
                onChange = {(e) => {
                    celSetText(e.target.value);
                 }}
                variant='standard'
                InputProps={ { disableUnderline: true, sx: { '& input': {paddingLeft: '5px'}} }}
                InputLabelProps={{ shrink: true, style: { marginTop: '-5px', color: 'white'} }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="message"
                label="Mensaje"
                name="mensaje"
                multiline
                rows={4}
                value={messageText}
                onChange = {(e) => {
                    messageSetText(e.target.value);
                 }}
                variant='standard'
                InputProps={ { disableUnderline: true, sx: { '& textarea': {paddingLeft: '5px', paddingTop: '5px'}} }}
                InputLabelProps={{ shrink: true, style: { marginTop: '-5px', color: 'white'} }}
            />
            <GoogleReCaptcha
                onVerify={verifyRecaptchaCallback}
            />
            <FormControlLabel id='checkBox' required control={<Checkbox style={{ color: 'white' }} />} sx={{ color: 'white' }} label="Autorizo que me contacten y me añadan a las bases de datos según las políticas de tratamiento de datos personales de TERRA DEVELOPMENT S.A.S" />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
                id='send'
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Enviar
            </Button>
            </Box>
            </Box>
        </Box>
        </Container>
        </GoogleReCaptchaProvider>
      </div>
      </div>
      <Modal
        if='containerModal'
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
         <Box sx={ {position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: '#47A4AF',
                    color: 'white',
                    border: 'nonde',
                    boxShadow: 24,
                    p: 4}}
                    id='modalBox'>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{ position: 'absolute', top: 5, right: 5, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalText}
        </Typography>
         </Box>
        </Modal>
      </>
    );
  };