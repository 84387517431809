import * as React from 'react';
import './card.css'



function Card({
    title,
    description,
    imageurl,
    position
}) {
    return (
        <div>
            {
                <div className={position == 'left' ? 'card cardLeft':'card cardRight' }>
                {position == 'left' ?
                    <div className='cardContent CardContentLeft'>
                        <div>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>
                        <div className='cardContentImage'><img src={imageurl} /></div>
                    </div>
                :  
                    <div className='cardContent CardContentRight'>
                        <div className='cardContentImage'><img src={imageurl} /></div>
                        <div>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                }
                </div>
            }
        </div>
    );
}
export default Card;