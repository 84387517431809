import React, { useEffect } from 'react';
import './about.css';

export default function About() {

    const [services, setServices] = React.useState([]);

    useEffect(() => {
        fetch('data/about.json')
            .then((r) => r.json())
            .then((data) => {
                setServices(data);
            })
    }, []);

    return (
      <>
      <div className='containerAbout'>
        <h1>¿Quiénes somos?</h1>
        <p>Confía en Terra y descubre cómo podemos transformar tu negocio en una fuente de excelencia y rentabilidad en el mercado actualmente competitivo. 
            Desarrollamos soluciones digitales para tus necesidades específicas.</p>
        <div className='containerImage'>
            {services.map(service => {
                return  <div className='aboutImage'>
                            <img src={require(`../../assets/images/about/${service.urlImage}`)} alt="SRC"/>
                            <h3>{service.name}</h3>
                            <h2>{service.description}</h2>
                        </div>
            })}
        </div>
      </div>
      </>
    );
};