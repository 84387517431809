// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect } from 'react';
// Import Swiper styles
import 'swiper/css';
import './carrousel.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import CardCarrousel from '../CardCarrousel';
import { IProjects } from '../../models/dto/IProjects.tsx';
import { Autoplay,Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

export default function Carrousel() {

  const [projects, setProjects] = React.useState([]);

  useEffect(() => {
    fetch('data/projects.json')
      .then((r) => r.json())
      .then((data) => {
        setProjects(data.response);
        console.log(projects)
      })
  }, []);


  return (
    <>
      <Swiper
        className='carrousel'
        slidesPerView={3}
        spaceBetween={35}
        navigation={true}
       
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
       
        breakpoints={{
          0: {
            slidesPerView: 1,

          },
          450: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
          }
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Autoplay,Navigation, Pagination, Mousewheel, Keyboard]}

      >
        {projects.map((item) => {
          return <SwiperSlide>
            <CardCarrousel
              title={item.title}
              description={item.description}
              urlImage={item.url} />
          </SwiperSlide>
        })}
      </Swiper>
    </>

  );
};